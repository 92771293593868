export const getDoctypeInternalBLPolicy = (category, subcategory) => {
  let docDescription = '';
  let docType = '';
  let retentionClass = '';
  let documentClassification = '';
  let dropFlag = '';
  let notification = '';

  // Mapping category to subcategory for Quote
  if (category === 'Documentation') {
    switch (true) {
    case subcategory === 'Cancellation Request Less Than 60 Days':
      docDescription = 'Request for Cancel less than 60 days';
      docType = 'CAN';
      retentionClass = 'UND185';
      documentClassification = 'Canc/Rein/Bill';
      dropFlag = 'N';
      notification = '';
      break;
    case subcategory === 'Certificate of Insurance':
      docDescription = 'Certificate of Insurance';
      docType = 'CERT';
      retentionClass = 'UND185';
      documentClassification = 'Report/UW Info';
      dropFlag = 'Y';
      notification = '';
      break;
    case subcategory === 'DA BOP Contents Verification':
      docDescription = 'DA BOP Contents Verification';
      docType = 'DACV';
      retentionClass = 'UND185';
      documentClassification = 'Report/UW Info';
      dropFlag = 'Y';
      notification = 'Underwriting_Support@libertymutual.com';
      break;
    case subcategory === 'DA Radius Verification':
      docDescription = 'DA Radius Verification';
      docType = 'DARV';
      retentionClass = 'UND185';
      documentClassification = 'Report/UW Info';
      dropFlag = 'Y';
      notification = 'Underwriting_Support@libertymutual.com';
      break;
    case subcategory === 'DA WC Verification':
      docDescription = 'DA WC Verification';
      docType = 'DAWCV';
      retentionClass = 'UND185';
      documentClassification = 'Report/UW Info';
      dropFlag = 'Y';
      notification = 'Underwriting_Support@libertymutual.com';
      break;
    case subcategory === 'General Correspondence':
      docDescription = 'Correspondence';
      docType = 'CORREX';
      retentionClass = 'UND185';
      documentClassification = 'Report/UW Info';
      dropFlag = 'N';
      notification = '';
      break;
    case subcategory === 'Policy Change Request':
      docDescription = 'Change Requests';
      docType = 'CHG REQ';
      retentionClass = 'UND185';
      documentClassification = 'Policy';
      dropFlag = 'N';
      notification = '';
      break;
    case subcategory === 'Proof of Mail':
      docDescription = 'Proof of Mail';
      docType = 'PROOF MAIL';
      retentionClass = 'UND185';
      documentClassification = 'Policy';
      dropFlag = 'Y';
      notification = '';
      break;
    }
  }

  return { docDescription, docType, retentionClass, documentClassification, dropFlag, notification };
}
